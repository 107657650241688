<template>
  <trev-product-skeleton-add ></trev-product-skeleton-add>
</template>

<script>
import trevProductSkeletonAdd from '../../../../../components/products/productskeletons/trev-product-skeleton-add.vue';
export default {
  components: {
    trevProductSkeletonAdd
  },
  metaInfo: () => ({
    title: 'Add Product Skeleton'
  })
}
</script>

<style>

</style>